export type Header = { [key: string]: string | null };

export type FetchOptions = {
  method?: 'GET' | 'POST';
  variables?: { [key: string]: any };
  signal?: AbortSignal;
  cache?:
    | 'default'
    | 'no-store'
    | 'reload'
    | 'no-cache'
    | 'force-cache'
    | 'only-if-cached';
};

export type FetchQueryError = Array<{
  message: string;
  extensions: { category: string };
}>;

const defaultHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

class FetchGraphQLMesh {
  public _endpoint?: string;

  get endpoint() {
    return this._endpoint;
  }

  get fetchGraphQlHeaders() {
    return this._fetchGraphQlHeaders;
  }

  public _fetchGraphQlHeaders: Header | undefined;

  public setEndpoint(endpoint: string) {
    this._endpoint = endpoint;
  }

  public setFetchGraphQlHeader(key: string, value: string | null) {
    this._fetchGraphQlHeaders = {
      ...this.fetchGraphQlHeaders,
      [key]: value,
    };
  }

  public removeFetchGraphQlHeader(key: string) {
    delete this._fetchGraphQlHeaders?.[key];
  }

  public setFetchGraphQlHeaders(header: Header) {
    this._fetchGraphQlHeaders = { ...header };
  }

  public async fetchGraphQl<T = any>(
    query: string,
    options?: FetchOptions
  ): Promise<{ errors?: FetchQueryError; data: T }> {
    const endpoint = this.endpoint;
    const fetchGraphQlHeaders = this.fetchGraphQlHeaders;

    if (!endpoint) throw Error('Missing "url"');

    const method = options?.method ?? 'POST';
    const cache = options?.cache;
    const signal = options?.signal;

    let body;
    const url = new URL(endpoint);
    const headers = {
      ...defaultHeaders,
      ...fetchGraphQlHeaders,
    };

    if (method === 'POST') {
      body = JSON.stringify({
        query,
        variables: options?.variables,
      });
    }

    if (method === 'GET') {
      url.searchParams.append('query', minimizeGraphQlQuery(query));

      if (options?.variables)
        url.searchParams.append('variables', JSON.stringify(options.variables));
    }

    return await fetch(url, {
      method,
      headers,
      body,
      cache,
      signal,
    }).then((r) => r.json());
  }

  public getConfig() {
    return {
      endpoint: this.endpoint,
      fetchGraphQlHeaders: this.fetchGraphQlHeaders,
    };
  }

  public getMethods() {
    return {
      setEndpoint: this.setEndpoint.bind(this),
      setFetchGraphQlHeader: this.setFetchGraphQlHeader.bind(this),
      removeFetchGraphQlHeader: this.removeFetchGraphQlHeader.bind(this),
      setFetchGraphQlHeaders: this.setFetchGraphQlHeaders.bind(this),
      fetchGraphQl: this.fetchGraphQl.bind(this),
      getConfig: this.getConfig.bind(this),
    };
  }
}

const mesh = new FetchGraphQLMesh();

export class FetchGraphQL extends FetchGraphQLMesh {
  get endpoint() {
    return this._endpoint ?? mesh.endpoint;
  }

  get fetchGraphQlHeaders() {
    return (
      (this._endpoint
        ? this._fetchGraphQlHeaders
        : { ...this._fetchGraphQlHeaders, ...mesh.fetchGraphQlHeaders }) || {}
    );
  }
}

function minimizeGraphQlQuery(query: string) {
  // Remove comments
  query = query.replace(/#.*/g, '');

  // Remove extra spaces, tabs, and line breaks
  query = query.replace(/\s+/g, ' ');

  return query.trim();
}

// Global Mesh instance
export const {
  setEndpoint,
  setFetchGraphQlHeaders,
  setFetchGraphQlHeader,
  removeFetchGraphQlHeader,
  fetchGraphQl,
  getConfig,
} = mesh.getMethods();
